import React, { useState, useEffect } from "react";
import { Layout, message } from "antd";
import { SideNav } from "./../components";
import { AppProps } from "next/app";
import "antd/dist/antd.css";
import { firebase, TopProgressBar, InternetStatus, setToken } from "shared";
import "nprogress/nprogress.css";
import "../main.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Sentry from "@sentry/node";
type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  decodedToken: any;
  setDecodedToken: any;
  setLoading: any;
  token: any;
};

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});
const useStyles = createUseStyles({
  siderMain: {
    backgroundColor: "#FFFFFF",
  },
  mainLayout: {
    backgroundColor: "#ffffff",
    borderLeft: "1px solid #f0f0f0",
  },
});
const MyApp = ({ Component, pageProps }: AppProps) => {
  const { Sider, Content } = Layout;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const { t } = useTranslation();
  useEffect(() => {
    initialLoad();
  }, []);
  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        const idToken = await user!.getIdTokenResult();
        if (idToken.claims["admin"]) {
          setUser(user);
          setToken(idToken.token);
        } else {
          message.error("Not an admin user");
        }
      }
      setLoading(false);
    });
  };

  const classes = useStyles();
  return (
    <>
      <InternetStatus
        noNotification
        window={global.window}
        message={t("No Internet")}
        description={t(
          "Internet connection couldn't be established, please check your connection."
        )}
      />
      <TopProgressBar />
      <AuthContext.Provider
        value={{
          loading,
          user,
          authenticated: user !== null,
          setUser,
          setLoading,
        }}
      >
        {user ? (
          <div>
            <Layout>
              <Sider className={classes.siderMain} width="240px" trigger={null}>
                <SideNav />
              </Sider>
              <Layout className={classes.mainLayout}>
                <div className="layoutStyle">
                  <Content className="contentStyle">
                    <Component {...pageProps} />
                  </Content>
                </div>
              </Layout>
            </Layout>
          </div>
        ) : (
          <Component {...pageProps} />
        )}
      </AuthContext.Provider>
    </>
  );
};
export default MyApp;
