import React, { useContext } from "react";
import {
  AppstoreOutlined,
  LogoutOutlined,
  CommentOutlined,
  MessageOutlined,
  TransactionOutlined,
  UserOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Router from "next/router";
import { firebase, MenuGenerator, removeToken } from "shared";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { AuthContext } from "../../../pages/_app";
import { destroyCookie } from "nookies";

const useStyles = createUseStyles({
  menu: {
    color: "#000000",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "normal",
    "& ul": {
      "li :before": {
        backgroundColor: "#fff",
      },
    },
    "& .ant-menu-item-selected:after ": {
      opacity: 0,
      backgroundColor: "#fff",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "63px",
    color: "#1890FF",
    marginLeft: 24,
  },
});
const SideNav = () => {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    destroyCookie({}, "accessToken", {
      path: "/",
    });
    removeToken();
    setUser(null);
    Router.push("/login", "/login", {
      shallow: true,
    });
  };

  const handleReviews = () => {
    Router.push("/reviews", "/reviews", {
      shallow: true,
    });
  };

  const handleComments = () => {
    Router.push("/comments", "/comments", {
      shallow: true,
    });
  };

  const handlePurchaseHistory = () => {
    Router.push("/purchase-history", "/purchase-history", {
      shallow: true,
    });
  };
  const classes = useStyles();

  const handleCarpetClick = () => {
    Router.push("/carpets", "/carpets", {
      shallow: true,
    });
  };
  const handleUserListClick = () => {
    Router.push("/user-lists", "/user-lists", {
      shallow: true,
    });
  };
  const handleArticleClick = () => {
    Router.push("/articles", "/articles", {
      shallow: true,
    });
  };
  const handleGuideClick = () => {
    Router.push("/guides", "/guides", {
      shallow: true,
    });
  };
  const handleInteriorClick = () => {
    Router.push("/interior", "/interior", {
      shallow: true,
    });
  };

  const data = [
    "/carpets",
    "/reviews",
    "/comments",
    "/purchase-history",
    "/articles",
    "/guides",
    "/interior",
  ];
  const index = data.indexOf(Router.pathname) || 0;

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          height: "64px",
          background: "#FFFFFF",
          boxShadow: " inset 0px -1px 0px #F0F0F0",
        }}
      >
        <strong className={classes.title}>Golestan Carpets</strong>
      </div>
      <MenuGenerator
        className={classes.menu}
        theme="light"
        mode="inline"
        defaultSelectedKeys={index > 4 ? [`${index + 2}`] : [`${index + 1}`]}
        defaultOpenKeys={
          index > 4
            ? index == 5
              ? [`${index + 1}`]
              : index == 6
              ? [`${index}`]
              : ["0"]
            : ["0"]
        }
        items={[
          {
            key: "1",
            onClick: handleCarpetClick,
            icon: <AppstoreOutlined />,
            name: t("Carpets"),
          },
          {
            key: "2",
            onClick: handleReviews,
            icon: <MessageOutlined />,
            name: t("Reviews"),
          },
          {
            key: "3",
            onClick: handleComments,
            icon: <CommentOutlined />,
            name: t("Comments"),
          },
          {
            key: "4",
            onClick: handlePurchaseHistory,
            icon: <TransactionOutlined />,
            name: t("Purchase History"),
          },
          {
            key: "5",
            onClick: handleArticleClick,
            icon: <FileTextOutlined />,
            name: t("Article Management"),
          },
          {
            key: "6",
            onClick: handleInteriorClick,
            icon: <AppstoreOutlined />,
            name: t("Interior Carpet Management"),
          },
          {
            key: "7",
            onClick: handleUserListClick,
            icon: <UserOutlined />,
            name: t("User Lists"),
          },
          {
            key: "8",
            onClick: handleGuideClick,
            icon: <InfoCircleOutlined />,
            name: t("Guide Management"),
          },
          {
            key: "10",
            onClick: handleSignOut,
            icon: <LogoutOutlined />,
            name: t("Sign out"),
          },
        ]}
      />
    </div>
  );
};

export { SideNav };
